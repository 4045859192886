import './App.css';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react'
import TeamBuilder from './Components/TeamBuilder';
import Division from './Components/Division';
import AutoSelect from './Components/AutoSelect'
import * as React from 'react';
import Alert from '@mui/material/Alert';
import BreadC from './Components/BreadCrumbs'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import AlertPop from './Components/AlertPop'
import Auth from './Components/Auth';
import { useCookies } from 'react-cookie'
import LogoutIcon from '@mui/icons-material/Logout'

function App() {
  const [step, setStep] = useState(-1)
  const [competitionOptions, setCompetitionOptions] = useState([''])
  const [competition, setCompetition] = useState(competitionOptions[0])
  const [teamMembers, setTeamMembers] = useState([])
  const [teamMemberName, setTeamMemberName] = useState("")
  const [setterTeamMemberOptions, setSetterTeamMemberOptions] = useState([])
  const [closerTeamMemberOptions, setCloserTeamMemberOptions] = useState([])
  const [teamName, setTeamName] = useState('')
  const [divisionSelection, setDivisionSelection] = useState('Setter')
  const [alertMessages, setAlertMessages] = useState([])
  const [displayAlert, setDisplayAlert] = useState(false)
  const [alertType, setAlertType] = useState()
  const [displaySuccess, setDisplaySuccess] = useState(false)
  const [lastName, setLastName] = useState('')
  const [userBadgeId, setUserBadgeId] = useState('')
  const [cookies, setCookie, removeCookie] = useCookies(['lastName', 'userBadgeId'])

  useEffect(() => {
    setAlertParams(["For best experience please use safari."], "", true)
    getCompetitionsOptions()
    getTeamMemberOptions()

    if(cookies.lastName && cookies.userBadgeId) {
      setStep(0)
    }
  }, [])

  const logout = () => {
    removeCookie('lastName')
    removeCookie('userBadgeId')

    window.location.reload()
  }

  const login = () => {
    fetch('/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        lastName,
        userBadgeId
      })
    })
    .then(res => {
      if(res.status === 200) {
        let expireDate = new Date()
        expireDate.setDate(expireDate.getDate() + 7)

        setCookie('lastName', lastName, {path: '/', expires: expireDate})
        setCookie('userBadgeId', userBadgeId, {path: '/', expires: expireDate})
        setStep(0)
      } else {
        const messages = [
          'Please enter a correct badge ID and last name to authenticate.',
          'If you continue to experience issues, please reach out to sales support.'
        ]

        setAlertParams(messages, 'Forbidden', true)
      }
    }) 
  }

  const getCompetitionsOptions = () => {
    fetch('/api/competitions')
    .then(res => res.json())
    .then(json => {
      setCompetitionOptions(json.modifiedData)
      setCompetition(json.modifiedData[0])
    })
  }

  const getTeamMemberOptions = () => {
    fetch('/api/team_member_options')
    .then(res => res.json())
    .then(json => {
      const setters = json.modifiedData.filter((tm) => {
        return tm.title === 'Setter'
      })
      const closers = json.modifiedData.filter((tm) => {
        return tm.title === 'Closer'
      })

      setSetterTeamMemberOptions(setters)
      setCloserTeamMemberOptions(closers)
    })
  }

  const validTeamStructure = (teamMembers, competition) => {
    const setters = teamMembers.filter(tm => {
      return tm.title === 'Setter'
    }).length
    const closers = teamMembers.filter(tm => {
      return tm.title === 'Closer'
    }).length
    const vets = teamMembers.filter(tm => {
      return tm.tenure === 'VETERAN' || tm.tenure === "SOPHOMORE"
    }).length
    const rookies = teamMembers.filter(tm => {
      return tm.tenure === 'ROOKIE'
    }).length

    if(competition.dth === "No" && competition.divisionSplit === "Yes" && divisionSelection === "Setter" && Number(competition.setters) === setters) {
      return true
    } else if (competition.dth === "No" && competition.divisionSplit === "Yes" && divisionSelection === "Closer" && Number(competition.closers) === closers) {
      return true
    } else if(competition.dth === "Yes" && competition.divisionSplit === "Yes" && divisionSelection === "Rookie" && Number(competition.setters) === rookies) {
      return true
    } else if (competition.dth === "Yes" && competition.divisionSplit === "Yes" && divisionSelection === "Veteran" && Number(competition.closers) === vets) {
      return true
    } else if (competition.dth === "No" && competition.divisionSplit === "No") {
      return setters === Number(competition.setters) && Number(competition.closers) === closers
    } else if (competition.dth === "Yes" && competition.divisionSplit === "No") {
      return rookies >= Number(competition.setters) && Number(competition.teamSize) === teamMembers.length
    } else {
      return false
    }
  }

  const setAlertParams = (messages, type, display) => {
    setAlertMessages(messages)
    setAlertType(type)
    setDisplayAlert(display)
  }

  const duplicateTeamMembers = (teamMembers) => {
    const originalCount = teamMembers.length
    const tmNames = teamMembers.map(tm => tm.label)
    const removedDups = [...new Set(tmNames)]

    return removedDups.length !== originalCount
  }

  const createTeam = (competition, teamName, teamMembers, division, address) => {
    setAlertParams([''], 'info', false)
    const addressString = `${address.street}, ${address.city}, ${address.state}, ${address.zip}`

    const duplicates = duplicateTeamMembers(teamMembers)
    const validStructure = validTeamStructure(teamMembers, competition)
    const validSize = Number(teamMembers.length) === Number(competition.teamSize)
    const invalid = !validStructure || !teamName || duplicates 

    let alerts = !validSize ? [`Invalid Team Size. Team must have exactly ${competition.teamSize} members. You've selected ${teamMembers.length}`] : []
    !teamName ? alerts.push('You need to choose a name for your team.') : alerts.push()
    duplicates ? alerts.push('You selected a rep more than once.') : alerts.push()
    !validStructure && competition.dth === "No" && competition.divisionSplit === "No" ? alerts.push(`Please make sure you have ${competition.setters} setters & ${competition.closers} closers.`) : alerts.push()
    !validStructure && competition.dth === "Yes" && competition.divisionSplit === "No" ? alerts.push(`Please make sure you have at least ${competition.setters} rookies & no more than ${competition.closers} vets.`) : alerts.push()

    if(invalid) {
      setAlertParams(alerts, 'Validation Error', true)
    } else {
      const competitionName = competition.label
      const teamCreator = `${cookies.lastName} - ${cookies.userBadgeId}`

      fetch('/api/team_members', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          teamName,
          competitionName,
          teamMembers,
          division,
          teamCreator,
          addressString
        })
      })
      .then(res => res.json())
      .then((res) => {
        if(res.errorMessage) {
          setAlertParams([res.errorMessage], 'Error', true)
        } else {
          setCompetition()
          setTeamName()
          setTeamMembers([])
          setDivisionSelection()
          setStep(0)
          setDisplaySuccess(true)
          // setAlertParams(['Team Created Successfully'], 'success', true)
        }
      })
      .catch((err) => alert(err.error))
    }
  }

  const handleFirstStep = () => {
    if(competition) {
      competition.divisionSplit === "Yes" ?  setStep(1) : setStep(2)
      setDivisionSelection(null) 
      setDisplayAlert(false)
      setDisplaySuccess(false)
    } else {
      setAlertParams(["You must select a competition first."], 'Validation Error', true)
    }
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: '#333'
      },
      secondary: {
        main: blue[100]
      }
    }
  })

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        { step !== -1 ? 
          <div id="logout-button">
            {/* <Button onClick={e => logout()}>Log Out</Button> */}
            <LogoutIcon
              onClick = {() => logout()}
            />
          </div>
        : null }
        <div id="header-box">
          <div id="breadcrumbs">
            { competition && step !== 0 && step !== -1 ? 
              <BreadC
                step={step} 
                setStep={setStep}
                competition={competition}
                setAlertParams={setAlertParams}
                divisionSelection={divisionSelection}
              />
            : null}
          </div>
          <div id="headerMain">
            <h1>
              Competition Registration
            </h1> 
          </div>
        </div>
        {step === -1 ?
          <Auth
            setStep={setStep}
            setUserBadgeId={setUserBadgeId}
            setLastName={setLastName}
            lastName={lastName}
            userBadgeId={userBadgeId}
            login={login}
          />
        : null}
        {step ===0 && competitionOptions.length === 0 ? 
          <div className='centered-form'>
            <h3>No competitions currently open for registration</h3>
          </div> 
        : null}
        {step === 0 && competitionOptions.length > 0 ?
          <div className='centered-form'>
            <div>
              <AutoSelect 
                options={competitionOptions}
                value={competition}
                setValue={setCompetition}
                placeholder={"Competition"}
                multi={false}
                setDisplaySuccess={setDisplaySuccess}
              />
            </div>
            <Stack spacing={2} direction="row">
              <Button className={'jbutton'} variant="contained" onClick={e => handleFirstStep()}>Submit</Button>
            </Stack>
          </div>
        : step === 1 ?
          <Division 
            divisionSelection={divisionSelection}
            competition={competition} 
            setStep={setStep}
            setDivisionSelection={setDivisionSelection}
            setAlertParams={setAlertParams}
            setDisplaySuccess={setDisplaySuccess}
          /> 
        : step === 2 ?
          <TeamBuilder 
            step={step} 
            competition={competition} 
            setTeamMembers={setTeamMembers} 
            teamMembers={teamMembers} 
            setTeamName={setTeamName}
            teamName={teamName}
            teamMemberName={teamMemberName}
            setTeamMemberName={setTeamMemberName}
            createTeam={createTeam}
            setterTeamMemberOptions={setterTeamMemberOptions}
            closerTeamMemberOptions={closerTeamMemberOptions}
            divisionSelection={divisionSelection}
          />
        : null }
        {displayAlert ? 
          <AlertPop 
            alertMessages={alertMessages}
            alertType={alertType}
            displayAlert={displayAlert}
            setAlertParams={setAlertParams}
          />
        : null}

        {displaySuccess ? 
          <Alert severity="success">Team Created Successfully</Alert>
        : null }
      </ThemeProvider>
    </div>
  );
}

export default App;
