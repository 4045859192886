import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';



export default function DisplayTeam(props) {
  const { title, groupMembers, removeTeamMember } = props

  return (
    <div>
      <div className='teamMember-box'>
        <h3>{title}s</h3>
        {groupMembers?.map((tm,i) => {
          return (
            <div id="team-member-list" key={tm?.label + i}>
              <div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                  <div style={{display: 'flex', flexDirection: 'row', padding: '0 0 5px 0'}}>
                    <strong style={{padding: '0 15px 0 0'}}>{i + 1}.</strong>
                    {tm.label}
                  </div>
                </div>
                <CloseIcon onClick={() => removeTeamMember(tm.label)}/>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
}

