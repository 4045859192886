import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import SearchSelect from './SearchSelect'
import DisplayTeam from './DisplayTeam';
import CloseIcon from '@mui/icons-material/Close';
import AddressForm from './AddressForm';

function TeamBuilder(props) {
  const {
    competition, 
    setTeamMembers, 
    teamMembers, 
    setTeamName, 
    teamName,
    createTeam,
    divisionSelection
  } = props
  const [setters, setSetters] = useState([])
  const [closers, setClosers] = useState([])
  const [vets, setVets] = useState([])
  const [rookies, setRookies] = useState([])
  const [address, setAddress] = useState({
    street: '',
    city: '',
    state: '',
    zip: '',
  });

  console.log(address)
  const handleChange = (event) => {
    const { name, value } = event.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };



  useEffect(() => {
    setSetters(teamMembers.filter(x => x.title === 'Setter'))
    setClosers(teamMembers.filter(x => x.title === 'Closer'))
    setVets(teamMembers.filter(x => x.tenure === 'VETERAN' || x.tenure === 'SOPHOMORE'))
    setRookies(teamMembers.filter(x => x.tenure == 'ROOKIE'))
  },[teamMembers])

  const removeTeamMember = (tm) => {
    const index = teamMembers.findIndex(x => x.label === tm)
    setTeamMembers([
      ...teamMembers.slice(0, index),
      ...teamMembers.slice(index + 1, teamMembers.length)
    ]);
  }

  return (
    <div className="App">
      <h3 id="header-tm">{competition.label}</h3>
      <div className='centered-form'>
        <div className='labeled'>
          <p>Team Name</p>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
            style={{width: '100%'}}
          >
            <TextField value={teamName} id="outlined-basic" /*>label="Team Name"*/ variant="outlined" onChange={e => setTeamName(e.target.value)} />
          </Box>
        </div> 
        { competition.includeAddress === "Yes" ?
        <div className='labeled'>
          <p style={{paddingBottom: '10px'}}>The team address will be used to ensure that all reps are selling within a 150 mile radius of a specific place during the competition rounds.</p>
          <AddressForm address={address} handleChange={handleChange} />
        </div> : null }
        <div className='labeled'>
          { competition.dth === "No" && (divisionSelection === 'Setter' || competition.divisionSplit === 'No') ? 
              <SearchSelect competition={competition} count={competition.setters} setValues={setTeamMembers} currentValues={teamMembers} title={'Setter'} tenure={null} dth={competition.dth} />
          : null }
        </div>

        { competition.dth === "Yes" ? 
        <p style={{marginBottom: '0'}}>{competition.teamSize} man team ({competition.setters} rookie minimum)</p> : null }
        <div className='labeled'>
          {competition.dth === 'Yes' && (divisionSelection === 'Rookie' || competition.divisionSplit === 'No') ? 
              <SearchSelect competition={competition} count={competition.setters} setValues={setTeamMembers} currentValues={teamMembers} title={'Rookie'} tenure={'ROOKIE'} dth={competition.dth}/>
          : null }
        </div>


        <div className='labeled'>
          {competition.dth === 'Yes' && (divisionSelection === 'Veteran' || competition.divisionSplit === 'No') ? 
              <SearchSelect competition={competition} count={competition.closers} setValues={setTeamMembers} currentValues={teamMembers} title={'Veteran'} tenure={"VETERAN"} dth={competition.dth}/>
          : null }
        </div>

        <div className='labeled'>
          { competition.dth === 'No' && (divisionSelection === 'Closer' || competition.divisionSplit === 'No') ?
              <SearchSelect competition={competition} count={competition.closers} setValues={setTeamMembers} currentValues={teamMembers} title={'Closer'} tenure={null} dth={competition.dth}/>
          : null }
        </div>

        <div>
          { competition.dth === "No" && (divisionSelection === 'Setter' || competition.divisionSplit === 'No') && setters.length > 0 ? 
            <DisplayTeam title={'Setter'} groupMembers={setters} removeTeamMember={removeTeamMember}/>
          : null}
          {competition.dth === 'Yes' && (divisionSelection === 'Rookie' || competition.divisionSplit === 'No') && rookies.length > 0 ? 
            <DisplayTeam title={'Rookie'} groupMembers={rookies} removeTeamMember={removeTeamMember}/>
          : null}
          <br></br>
          <br></br>
          { competition.dth === 'No' && (divisionSelection === 'Closer' || competition.divisionSplit === 'No') && closers.length > 0 ? 
            <DisplayTeam title={'Closer'} groupMembers={closers} removeTeamMember={removeTeamMember}/>
          : null}
          {competition.dth === 'Yes' && (divisionSelection === 'Veteran' || competition.divisionSplit === 'No') && vets.length > 0 ? 
            <DisplayTeam title={'Veteran'} groupMembers={vets} removeTeamMember={removeTeamMember}/>
          : null}
        </div>
        <Stack spacing={2} direction="row">
          <Button className='jbutton' variant="contained" onClick={e => createTeam(competition, teamName, teamMembers, divisionSelection, address)}>Submit</Button>
        </Stack>
      </div>
    </div>
  );
}

export default TeamBuilder;